import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_fuyacho-nijo_ja.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_fuyacho-nijo_en.pdf' : 'floorPlan_fuyacho-nijo_ja.pdf';
    setFloorPdf(pdffile)
  });
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#4</p>
            <h2 className="facility_dMvTit">京の温所 麩屋町二条<span>FUYACHO-NIJO</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/34cf7af73504ad7e73da9ba0e55a1756.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">伝統文化が息づく京町家と「カリモク家具」<br />大人数でゆったりと滞在できる宿</h2>
              <p>御所の南に位置し、京都市内どこへ行くにも便利な麩屋町二条。<br />約150年前に建てられた大きな京町家を、間取りや当時の雰囲気をできるだけ残しつつ、今の暮らしに寄り添うようにリノベーションしました。<br />日本の伝統文化を上質な素材を使って表現した「おうち」の中に「カリモク家具」が溶け込み、新鮮さと共に、どこか懐かしさも感じるここちよい空間です。<br />また、8名様までゆったりとご滞在いただける大きな京町家なので、気のおけない友人と、久しぶりに会う同級生と、いつも離れて暮らしている家族や親せきと、ひとつ屋根の下で温かな時をお過ごしください。</p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "/news/recommend/#fuyacho-nijo",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 麩屋町二条 #4<br /><a href="https://goo.gl/maps/248fFseDj4XwBFRu7" target="_blank">京都市中京区麩屋町通二条上る布袋屋町502</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て186平米（1棟貸切）</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～8名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>&lt;1階&gt; キッチン＆ダイニング 和室1（掘りごたつ）<br />和室2　庭　風呂（信楽焼・2シャワー） 洗面（2ボウル） トイレ<br />
                    &lt;2階&gt; ベッドルーム1（小上がりミニライブラリー）<br />ベッドルーム2 中の間（カウチスペース） トイレ</>,
                },
                {
                  label: <>寝具</>,
                  value: <>セミダブルベッド4台　ふとん4組（5名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、掘りごたつ、床暖房、浄水器（クリンスイ）</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>あり（19インチ ポータブルタイプ）<br />※インターネット、機器等との接続不可</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/86bca76ca418b4b5421e12c1f6f62ed8.jpg',
              '/images/facility/e9ec7129fab8cea00a70fb2dfa3c0d30.jpg',
              '/images/facility/282b344aff0b3afab3cf2ae73aa51f81.jpg',
              '/images/facility/9ec6372e686887d8417529724a44136c.jpg',
              '/images/facility/212b8b55a7a7f386954fc141217030c9.jpg',
              '/images/facility/d7f01f878dcc0c6edbb2964bcd590abc.jpg',
              '/images/facility/46a3caa6b9f3b5d10df092edbc9ed2a5.jpg',
              '/images/facility/d4ae80f942b8bc6cff991ced5fe34687.jpg',
              '/images/facility/04678bed5633ffb8fbeef40cea88f1f6.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">伝統文化や技法をモダンに取り入れた新鮮さと懐かしさ</h2>
            <p>古くから変わらない価値を持ちながら、現代では見かけることが少なくなってしまった伝統文化があります。「京の温所 麩屋町二条」はそんな文化に出逢える宿。<br />文様を取り入れた組子欄間やタイル、信楽焼きの浴槽や洗面台など、日本の伝統文化や技法を取り入れています。<br />また、各部屋では伝統的な技法で表現された現代アート作品も楽しんでいただけます。特に印象的なのはダイニング壁一面の「野田版画工房」の唐紙。「歴史ある京町家や京都の暮らしを次の世代に繋いでいく」という「京の温所」のコンセプトを、円のモチーフと、過去の海の音を鳴らし未来に繋いでいく法螺貝の地文様で表現しています。<br />さらに1階の和室には炉や躙り口（にじりぐち）を設え、茶室としてもお使いいだけます。
            </p>
            <ImgList data={[
              '/images/facility/d0c445ad2d1958a9f278e58dde0e3c36.jpg',
              '/images/facility/119a6f956afb85e2aa20685fda6b696e.jpg',
              '/images/facility/3344b7a4b6dccf578558ce4ce8194a28.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">大勢で楽しめるキッチンと円卓で囲むダイニング</h2>
              <p>キッチンは約6帖で広々。気の合う仲間と賑やかに料理をつくり、食べる楽しみをご堪能ください。
                京町家の台所の面影をできるだけ残すため、役目を終えた「おくどさん（かまど）」の上には、火難から護り家族の幸せを願う「布袋さん」を、シンクの上には井戸で使われていた釣瓶（つるべ・井戸の滑車）を据えました。家族で食べる楽しさや幸せを育んできた台所の思い出を残し、次世代に受け継ぐキッチンです。<br />また、ダイニングにあるのは8人がゆったり座れる大きな丸いウォールナット材のテーブル。温かな料理をみんなで囲みながら、おしゃべりが弾みます。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/82b7472c3c5b90f88970d706670ce2d7.jpg',
              '/images/facility/e2903333077485bba353fcaaf0306a40.jpg',
              '/images/facility/148d39c18d8af9d00e58ef9a9241b2ce.jpg',
            ]} />
            <KitchenList />
          </section>
          <section id="facility_dFeature" className="facility_d facility_dSection">
            <h2 className="facility_dTit">京町家に溶け込む「カリモク家具」</h2>
            <p>「京の温所 麩屋町二条」の家具は、すべて選び抜いた素材を使い熟練の職人の手でつくられた「カリモク家具」のもの。デザインとここちよさが共存する木の家具が、京町家に溶け込んでいます。<br />存在感あるダイニングテーブルに贅沢に使われているのはウォールナット材。寄せ張りが生み出す木目の美しさが、時を重ねるごとに落ち着いた表情に変化していきます。また、庭を望む和室の座卓には、ほのかに赤みを帯びた上品な色みと光沢が美しいサクラ材を使っています。<br />そして2階のふたつのベッドルームの間にあるのは、どっしりとしたフォルムのカウチソファ。<br />そのほかにも各部屋に配された、木の家具ならではの美しい木目とやわらかさ、ぬくもりを感じる「カリモク家具」に触れてください。</p>
            <ImgList data={[
              '/images/facility/01-190213_1.jpg',
              '/images/facility/02-190213_1.jpg',
              '/images/facility/03-190213_1.jpg',
            ]} />
          </section>
          <AmenitieList data={[
            '/images/facility/eba68ebc579b97cacc58d0eb7cc96eff.jpg',
            '/images/facility/amenities_bath.jpg',
            '/images/facility/amenities_pajamas.jpg',
          ]} />
          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄東西線「京都市役所前」駅ゼスト御池口　徒歩約8分<br />地下鉄烏丸線「丸太町」駅5番出口　徒歩約10分</>,
              bus: <>「京都市役所前」下車　徒歩約8分</>,
              taxi: "",
              parking: <>駐車場はございません。（近隣パーキング：徒歩5分圏内数か所あり）</>,
              img: "",
              maplink: "https://goo.gl/maps/248fFseDj4XwBFRu7",
              map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.7047153044373!2d135.76350591524073!3d35.01409618035527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600109d2aa730151%3A0x32ac74b229d0cdeb!2z5Lqs44Gu5rip5omAIOm6qeWxi-eUuuS6jOadoSAjNA!5e0!3m2!1sja!2sjp!4v1565333973265!5m2!1sja!2sjp",
            }} />
          </section>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001668" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
